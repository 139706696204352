<template>
  <div class="home">
    <div class="header">
      <h1>{{ $t('lesovosstanovlenie') }}</h1>

      <div class="header-cta">
        <a href="#donate" class="btn">{{$t('plant-a-tree')}}</a>
      </div>

      <div class="trees-planted">
        <div class="title">{{$t('blagodarya-vam')}}</div>
        <div>{{$t('my-posadili-uzhe')}} <span class="counter">{{ treeCount }}</span> {{$t('derevo')}}</div>
      </div>
    </div>

    <div id="donate" class="section sec-donate">
      <div class="hdg1 tac">{{$t('vnesti-pozhertvovanie')}}</div>
      <p v-html="$t('donate')"></p>
      <div class="tac"><svg-icon class="icon" icon="icon-tree" /></div>
      <form method="GET" action="/other/donate.php" class="hbox alic just-cn form-donate">
        <div class="pay-email-row">
          <div class="pay-input">
            <div class="currency">₽</div>
            <input v-model="donateSum" type="number" min="100" name="sum" placeholder="100" @blur="validateSum" />
          </div>
          <div class="email-input">
            <input v-model="email" type="email" name="email" placeholder="e-mail" @blur="validateEmail" />
          </div>
        </div>
        <button class="btn btn-pay" :disabled="emailError !== null || sumError !== null">{{$t('vnesti')}}</button>
      </form>

      <div class="error-container">
        <span v-if="sumError" class="error-message">{{ sumError }}</span>
        <span v-if="emailError" class="error-message">{{ emailError }}</span>
      </div>
    </div>

    <div class="section sec-why">
      <div class="img-title">
        <div class="title">{{$t('zachem-sazhat-derevya')}}</div>
        <div class="subtitle">{{$t('kakova-polza-dlya-nashei-planety')}}</div>
      </div>

      <div class="wlimit">
        <div class="hdg1">{{$t('pochemu-derevya-tak-vazhny-dlya-okruzhayushei-sredy')}}</div>
        <p>
          {{$t('derevya-pomogayut-ochishat-vozdukh')}}
        </p>
        <p>
          {{$t('vot-shest-faktov-kotorye-obyasnyayut-pochemu-derevya-tak-vazhny')}}
        </p>

        <app-paragraphs />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import AppParagraphs from '../components/AppParagraphs.vue'
export default {
  name: 'Home',
  data() {
    return {
      donateSum: '100',
      email: '',
      emailError: null,  // null вместо пустой строки
      sumError: null,    // null вместо пустой строки
      treeCount: 0
    }
  },
  mounted() {
    axios.get('https://dobroedelo39.ru/other/treecount.php').then((data) => {
      this.treeCount = data.data;
      console.log('trees' + data.data);
    })
  },
  methods: {
    validateEmail() {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.email) {
        this.emailError = this.$t('please_input_email');
      } else if (!emailPattern.test(this.email)) {
        this.emailError = this.$t('email_incorrect_format');
      } else {
        this.emailError = null;
      }
    },
    validateSum() {
      if (this.donateSum < 100) {
        this.sumError = this.$t('summa_100_min');
      } else {
        this.sumError = null;
      }
    }
  },
  components: {
    AppParagraphs
  }
}
</script>

<style lang="less" scoped>
.form-donate {
  margin-top: 16px;
  flex-wrap: wrap;
  gap: 16px;
}

.pay-input {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  position: relative;
}

.pay-input .currency {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-weight: bold;
}

.pay-input input {
  padding-left: 30px;
  width: 100px;
}

.email-input {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pay-email-row {
  display: flex;
  gap: 16px;
  align-items: center;
}

.email-input input {
  width: 200px;
}

.error-container {
  margin-top: 16px;
  text-align: center;
}

.error-message {
  display: block;
  color: red;
  font-size: 14px;
  margin-bottom: 4px;
}

.sec-donate {
  padding-top: 6rem;
  .icon {
    width: 34px;
    height: 34px;
  }
  .hdg1 + .tac {
    margin-top: 16px;
    color: #107E7B;
  }
}

.noqiwi {
  text-align: center;
  color: red;
  font-size: 18px;
  position: relative;
  top: 60px;
}
</style>