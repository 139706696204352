<template>
  <div id="app">
    <icon-system />
    <nav-header />
    <router-view />
    <app-footer />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader'
import IconSystem from '@/components/IconSystem'
import AppFooter from '@/components/AppFooter'
export default {
  components: {
    NavHeader,
    IconSystem,
    AppFooter
  },
  mounted() {
    if (process.env.NODE_ENV === 'production') {
      this.addLiveInternetCounter();
    }
  },
  methods: {
    addLiveInternetCounter() {
      const liveInternetContainer = document.createElement('div');
      liveInternetContainer.innerHTML = `
        <a href="https://www.liveinternet.ru/stat/dobroedelo39.ru/index.html?lang=ru" target="_blank">
          <img id="licntFDAE" width="88" height="120" style="border:0"
          title="LiveInternet: показано количество просмотров и посетителей"
          src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAEALAAAAAABAAEAAAIBTAA7"
          alt=""/>
        </a>
      `;

      document.body.appendChild(liveInternetContainer);

      // Создаём отдельный скрипт для динамической загрузки данных
      const liveInternetScript = document.createElement('script');
      liveInternetScript.innerHTML = `
        (function(d,s){
          d.getElementById("licntFDAE").src =
          "https://counter.yadro.ru/hit?t27.6;r" + encodeURIComponent("https://yandex.ru/search/?text=dobroedelo39") +
          ";u" + encodeURIComponent(d.URL) +
          ";h" + encodeURIComponent(d.title.substring(0,150)) +
          ";" + Math.random();
        })(document,screen);
      `;

      document.body.appendChild(liveInternetScript);
    }
  }
}
</script>
